import React, { createContext, useContext, useState } from 'react';

interface ModalData {
  id: string;
  Component: React.Component | any;
  state: boolean;
}

interface ModalContextInterface {
  registerModal(data: ModalData): void;
  changeModalState(id: string,state: boolean): void;
  getModalState(id: string): boolean;
}

interface ModalInterfaceProps {
  children: React.Component | any;
}

export const ModalContext = createContext<ModalContextInterface>(
  {} as ModalContextInterface
);

export default function ModalProvider({ children }: ModalInterfaceProps) {
  const [modals, setModals] = useState<ModalData[]>([]);

  const registerModal = (data: ModalData) => {
    const modal = modals.find((dataI) => dataI.id == data.id);
    if(modal == undefined) setModals((state) => [...state, data]);
  };

  const changeModalState = (id: string, state:boolean) => {
    const modalIndex = modals.findIndex((data) => data.id == id);
    if (modalIndex >= 0) {
      const newModals = modals.slice();
      newModals[modalIndex].state = state;
      setModals(newModals);
    }
  };

  const getModalState = (id: string) => {
    const modal = modals.find((data) => data.id == id);
    return modal?.state || false;
  };
  return (
    <ModalContext.Provider
      value={{ registerModal, changeModalState, getModalState }}
    >
      {modals.map(({Component, id}) => (
        <Component key={id}/>
      ))}
      {children}
    </ModalContext.Provider>
  );
}

export const useModal = () => {
  return useContext(ModalContext);
};
