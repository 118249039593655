import {
  Box,
  Divider,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { useModal } from '@context/modalsContext';

import { WinnerPrize } from '@interfaces/Winners';
import { modalRegisterID } from '@lib/consts_envs';
import {
  getWinnerPrizesDetailReq,
  getWinnerPrizesReq,
} from '@services/winner_prize';
import { useEffect, useState } from 'react';

import { SlClose } from 'react-icons/sl';

export default function WinnersModal() {
  const [winners, setWinners] = useState<WinnerPrize[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const { changeModalState, getModalState } = useModal();

  const handleOnCloseModal = () => {
    changeModalState(modalRegisterID, false);
  };

  useEffect(() => {
    console.log(getModalState(modalRegisterID));
    if (getModalState(modalRegisterID) == true) {
      handleOpenModal();
    }
  }, [getModalState(modalRegisterID)]);

  const handleOpenModal = async () => {
    setIsLoading(true);
    changeModalState(modalRegisterID, true);

    if (winners != undefined && winners.length > 0) {
      setIsLoading(false);
      return;
    }

    let winnersResp = await getWinnerPrizesReq();
    if (winnersResp != undefined) {
      for (let index = 0; index < winnersResp.length; index++) {
        const element = winnersResp[index];
        const winnerPrizeResp = await getWinnerPrizesDetailReq({
          idPrize: element.idPrize,
          type: element.type,
        });
        if (winnerPrizeResp != undefined) {
          winnersResp[index].winnerPrizeDetails = winnerPrizeResp;
        }
      }

      setWinners(winnersResp);
    }
    setIsLoading(false);
  };

  const renderExibitionInfos = (winnersPrize: WinnerPrize) => {
    if (isLargerThan768) {
      return (
        <TableContainer w={'full'}>
          <Table variant="unstyled">
            <Thead>
              <Tr textTransform="none">
                <Th textTransform="none">
                  <Text variant={'tableHeaderText'}>Nome</Text>
                </Th>
                <Th textTransform="none">
                  <Text variant={'tableHeaderText'}>Celular</Text>
                </Th>
                <Th textTransform="none">
                  <Text variant={'tableHeaderText'}>Município/UF</Text>
                </Th>
                <Th textTransform="none">
                  <Text variant={'tableHeaderText'}>Status da entrega</Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {winnersPrize.winnerPrizeDetails
                ?.sort(function (a, b) {
                  const now = new Date();
                  const aDate =
                    a.dt_delivery != null ? new Date(a.dt_delivery) : now;
                  const bDate =
                    b.dt_delivery != null ? new Date(b.dt_delivery) : now;
                  return aDate.getTime() - bDate.getTime();
                })
                .map((e) => {
                  return (
                    <Tr>
                      <Td>
                        <Text variant="tableBodyText">{e.login || '-'}</Text>
                      </Td>
                      <Td>
                        <Text variant="tableBodyText">{e.phone || '-'}</Text>
                      </Td>
                      <Td>
                        <Text variant="tableBodyText">{e.address || '-'}</Text>
                      </Td>
                      <Td>
                        <Text variant="tableBodyText">{e.status || '-'}</Text>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </TableContainer>
      );
    }
    return (
      <VStack spacing={10} py={2} w="full">
        {winnersPrize.winnerPrizeDetails
          ?.sort(function (a, b) {
            const now = new Date();
            const aDate = a.dt_delivery != null ? new Date(a.dt_delivery) : now;
            const bDate = b.dt_delivery != null ? new Date(b.dt_delivery) : now;
            return aDate.getTime() - bDate.getTime();
          })
          .map((e, index) => {
            // let date = '';
            // if (e.dt_delivery != null && e.dt_delivery != undefined) {
            //   date = new Date(e.dt_delivery).toLocaleDateString('pt-BR');
            // }
            return (
              <VStack
                alignItems="start"
                w="full"
                key={`winner_${index}`}
                px={2}
                color="#000"
                fontFamily="AMX"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="100%" /* 16px */
              >
                <HStack w="full" justify="space-between">
                  <Text fontWeight="bold">Nome: </Text>{' '}
                  <Text>{e.login || '-'}</Text>
                </HStack>
                <HStack w="full" justify="space-between">
                  <Text fontWeight="bold">Celular: </Text>{' '}
                  <Text>{e.phone || '-'}</Text>
                </HStack>
                <HStack w="full" justify="space-between">
                  <Text fontWeight="bold">Município/UF: </Text>{' '}
                  <Text>{e.address || '-'}</Text>
                </HStack>
                <HStack w="full" justify="space-between">
                  <Text fontWeight="bold">Status da entrega: </Text>
                  <Text>{e.status || '-'}</Text>
                </HStack>
              </VStack>
            );
          })}
      </VStack>
    );
  };

  return (
    <Modal
      size="full"
      isOpen={getModalState(modalRegisterID)}
      onClose={handleOnCloseModal}
    >
      {/* <ModalOverlay /> */}
      <ModalContent
        mt={['60px', '90px']}
        background="#B41E13"
        zIndex="20"
        py={10}
        px={[5, 20]}
      >
        <ModalHeader>
          <HStack justify={'space-between'}>
            <Box w="20px" h="20px" />
            <Text
              textAlign="center"
              fontFamily="AMX"
              fontSize="40px"
              fontStyle="normal"
              fontWeight="700"
              lineHeight="normal"
              color="#FFC722"
            >
              Ganhadores
            </Text>
            <IconButton
              aria-label="Close"
              variant="ghost"
              fontSize="53px"
              icon={<SlClose fontSize="53px" color="white" />}
              onClick={() => handleOnCloseModal()}
            />
          </HStack>
        </ModalHeader>
        <ModalBody mt={'20px'}>
          {isLoading ? (
            <VStack>
              <Spinner size="xl" speed="0.65s" color='white' />
              <Text fontSize="sm" color='white'>aguarde</Text>
            </VStack>
          ) : (
            <VStack spacing={20}>
              {winners?.map((item) => (
                <VStack
                  bgColor="#FFFFFF"
                  borderRadius={'12px'}
                  py={'30px'}
                  px={'6vw'}
                  w={'full'}
                >
                  <Stack
                    direction={{
                      base: 'column',
                      sm: 'column', // 480px
                      md: 'row', // 768px
                      lg: 'row', // 992px
                      xl: 'row', // 1280px
                      '2xl': 'row', // 1536px
                    }}
                    fontFamily="AMX"
                    fontSize={{
                      base: '18px',
                      sm: '18px', // 480px
                      md: '18px', // 768px
                      lg: '32px', // 992px
                      xl: '32px', // 1280px
                      '2xl': '32px', // 1536px
                    }}
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="30px"
                    color="#DA291C"
                    justify={'space-between'}
                    py={5}
                    w={'full'}
                  >
                    <Text> Sorteio dia XX/XX/XXXX</Text>
                    <Text  
                    fontWeight={{
                      base: '400',
                      sm: '400', // 480px
                      md: '400', // 768px
                      lg: '700', // 992px
                      xl: '700', // 1280px
                      '2xl': '700', // 1536px
                    }}
                    > {item.title}</Text>
                  </Stack>
                  <Divider orientation="horizontal" bgColor={'#000000'} />
                  {renderExibitionInfos(item)}
                </VStack>
              ))}
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
