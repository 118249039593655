import WinnersModal from '@components/WinnersModal';
import { useModal } from '@context/modalsContext';
import { modalRegisterID } from '@lib/consts_envs';
import React, { useEffect }  from 'react';

export default function ModalRegisters() {
  const { registerModal } = useModal();

  useEffect(()=>{
      registerModal({
        id: modalRegisterID,
        Component:WinnersModal,
        state:false
      })
  },[])
  return (<div></div>
  );
}
